import { PropsWithChildren } from 'react';
import { useMutationState } from '@tanstack/react-query';

import { Button } from '@blockworks/ui/components';
import { GooglePrimaryIcon, OutlookPrimaryIcon } from '@blockworks/ui/icon';

import { authMutations } from '../../api/mutations';
import { useCallbackUrlParam } from '../../hooks';

const providerConfig = {
    google: {
        icon: GooglePrimaryIcon,
    },
    'microsoft-entra-id': {
        icon: OutlookPrimaryIcon,
    },
};

type OAuthButtonProps = PropsWithChildren<{
    provider: keyof typeof providerConfig;
}>;

const OAuthButton = ({ provider, children }: OAuthButtonProps) => {
    const { icon } = providerConfig[provider];
    const callbackUrl = useCallbackUrlParam();
    const anySignIn = useMutationState({ filters: { mutationKey: authMutations.signin.use.getKey() } });
    const signInMutation = authMutations.signin.use();

    return (
        <Button
            w="full"
            center
            borderRadius="md"
            leadingIcon={icon}
            disabled={anySignIn.some(({ status }) => ['pending', 'success'].includes(status))}
            onClick={() => signInMutation.mutate({ provider, options: { callbackUrl } })}
        >
            {children}
        </Button>
    );
};

export type { OAuthButtonProps };
export { OAuthButton };
