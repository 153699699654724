import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const alertStyles = tVariants({
    base: 'alert rounded-md flex justify-between items-center border gap-2 bg-current-bg',
    variants: {
        variant: {
            outline: '[--color-current-bg:--alert-fill-alt] border-[--alert-outline] text-[--alert-text]',
            fill: '[--color-current-bg:--alert-fill] text-[--alert-text] border-current-bg',
        },
        intent: {
            info: '[--alert-text:--color-content-muted] [--alert-outline:--color-surface-selected] [--alert-fill:--color-surface-muted] [--alert-fill-alt:--color-surface-depth]',
            warning:
                '[--alert-text:--color-warning-content] [--alert-outline:--color-warning-surface] [--alert-fill:--color-warning-surface] [--alert-fill-alt:--color-warning-surface]',
            success:
                '[--alert-text:--color-success-content] [--alert-outline:--color-success-surface] [--alert-fill:--color-success-surface] [--alert-fill-alt:--color-success-surface]',
            danger: '[--alert-text:--color-danger-content] [--alert-outline:--color-danger-surface] [--alert-fill:--color-danger-surface] [--alert-fill-alt:--color-danger-surface]',
        },
        ...factoryStyleProps.space,
        fontSize: factoryStyleProps.typography.fontSize,
        size: {
            sm: 'p-2',
            md: 'p-4',
        },
    },
    defaultVariants: {
        variant: 'outline',
        intent: 'info',
        size: 'md',
    },
});

export { alertStyles };
