import { useRouter } from 'next/router';

const useCallbackUrlParam = () => {
    const router = useRouter();
    const callbackUrl = Array.isArray(router.query.callback) ? router.query.callback[0] : router.query.callback;
    if (!callbackUrl) return undefined;
    return decodeURIComponent(callbackUrl);
};

export { useCallbackUrlParam };
